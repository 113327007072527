import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Axios from 'axios';
import useForm from 'react-hook-form';
import Img from 'gatsby-image';
import { navigate } from 'gatsby';
import SEO from '../components/seo';
import FormButton from '../components/buttons/form-button';
import { getGoogleClickIdentifier } from '../services/google-click-identifier';
import { Route } from '../models/site-links';
import './form.css';
import './lead-form.css';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

const GetIdScannerAppPage = () => {
  const [gclid, setGCLID] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      // Get the Google Click Identifier after a small delay to allow it to be loaded
      setGCLID(getGoogleClickIdentifier());
    }, 100);
  }, []);
  const data = useStaticQuery(graphql`
    query {
      scannerKiosk: file(relativePath: { eq: "feature-images/our-experts.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { register, handleSubmit } = useForm();
  const submitForm = (data: any) => {
    if (typeof window !== 'undefined') {
      const anyWindow = window as any;
      if (anyWindow.fbq != null) {
        anyWindow.fbq('track', 'Lead');
      }
    }
    const formData = {
      name: data.name,
      organization: data.organization,
      email: data.email,
      phone: data.phone,
      your_industry: data.industry,
      message: data.message,
      country: data.country,
      lead_page: 'Vaccine Passport Page - PiMobile Download Request',
      gclid: data.gclid,
    };
    Axios.post(
      `https://prod-01.canadacentral.logic.azure.com:443/workflows/844eec674e744991b5309933956c9f36/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=pkYa_Lwa_a8jnpMqdOa3un2ixjx_XI4zd_zvcL0JIts`,
      formData,
    ).then((data: any) => {
      navigate(Route.ExpertThanks);
    });
  };

  return (
    <div className="lead-page-container">
      <SEO title="Ask an Expert - Patronscan" />
      <div className="talk-expert-container">
        <div className="lead-form-appeal">
          <h1 className="lead-tagline-2 font-subheaading thin-heading">
            Get Patronscan for iOS or Android
          </h1>
        </div>
        <div className="expert-form-photo">
          <div className="expert-form-image-container">
            <Img fluid={data.scannerKiosk.childImageSharp.fluid} />
          </div>
          <div className="expert-lead-form-container">
            <div className="lead-form">
              <div className="lead-form-tagline font-subheading">
                <h2>Request Download Link</h2>
              </div>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="inline-flex flex-wrap w-full form-field">
                  <label className="w-full block form-text-base mb-2 form-label" htmlFor="email">
                    Email*
                  </label>
                  <input
                    className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                    placeholder="Email*"
                    id="email"
                    name="email"
                    type="email"
                    ref={register({ required: true })}
                  />
                </div>
                <div className="inline-flex flex-wrap w-full form-field">
                  <label className="w-full block form-text-base mb-2 form-label" htmlFor="name">
                    Name*
                  </label>
                  <input
                    className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                    placeholder="Name*"
                    id="name"
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                  />
                </div>
                <div className="inline-flex flex-wrap w-full form-field">
                  <label
                    className="w-full block form-text-base mb-2 form-label"
                    htmlFor="organization"
                  >
                    Company
                  </label>
                  <input
                    className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                    placeholder="Business Name"
                    id="organization"
                    name="organization"
                    type="text"
                    ref={register({ required: false })}
                  />
                </div>
                <div className="inline-flex flex-wrap w-full form-field">
                  <label className="w-full block form-text-base mb-2 form-label" htmlFor="phone">
                    Phone Number
                  </label>
                  <input
                    className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                    placeholder="Phone Number"
                    id="phone"
                    name="phone"
                    type="phone"
                    ref={register({ required: false })}
                  />
                </div>
                <div className="inline-flex flex-wrap w-full form-field">
                  <label className="w-full block form-text-base mb-2 form-label" htmlFor="message">
                    Anything Else You'd Like Us To Know?
                  </label>
                  <textarea
                    className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                    id="message"
                    placeholder="Anything Else You'd Like Us To Know?"
                    name="message"
                    ref={register}
                  ></textarea>
                </div>
                <div className="inline-flex flex-wrap w-full form-field">
                  <label className="w-full block form-text-base mb-2 form-label" htmlFor="industry">
                    Your Industry
                  </label>
                  <select
                    className="border-blue p-2 rounded-lg border block bg-white form-text-base form-input-field"
                    name="industry"
                    id="industry"
                    ref={register({ required: true })}
                  >
                    <option selected hidden className="option-select">
                      Select Industry
                    </option>
                    <option value="Bar & Nightclub">Bar & Nightclub</option>
                    <option value="Property Management">Property Management</option>
                    <option value="Liquor Store">Liquor Store</option>
                    <option value="Retail Store">Retail</option>
                    <option value="Gaming">Gaming</option>
                    <option value="Telecom">Telecom</option>
                    <option value="Hotel">Hotel</option>
                    <option value="Automotive">Automotive</option>
                    <option value="Retail Cannabis">Retail Cannabis</option>
                    <option value="Retail Vape">Retail Vape</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="inline-flex flex-wrap w-full form-field">
                  <label className="w-full block form-text-base mb-2 form-label" htmlFor="country">
                    Country
                  </label>
                  <select
                    className="border-blue p-2 bg-white rounded-lg border block form-text-base form-input-field"
                    name="country"
                    id="country"
                    ref={register({ required: false })}
                  >
                    <option selected hidden>
                      Select Country
                    </option>
                    <option value="Australia">Australia</option>
                    <option value="Canada">Canada</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <input
                  id="gclid"
                  name="gclid"
                  value={gclid}
                  type="hidden"
                  ref={register({ required: false })}
                />
                <div className="">
                  i
                  <FormButton
                    formId="GetIdScannerAppSubmit"
                    className="form-submit-button"
                    text="Submit"
                    type="submit"
                  />
                </div>
              </form>
            </div>
            <div className="submission-button"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetIdScannerAppPage;
